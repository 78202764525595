//import {APIProvider, Map, Marker, AdvancedMarker, AdvancedMarkerAnchorPoint} from '@vis.gl/react-google-maps';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import {APIProvider, Map, AdvancedMarker, AdvancedMarkerAnchorPoint, useMap} from '@vis.gl/react-google-maps';
import truck from './truck.png';
import van from './van.png';
import car from './car.png';

function deepCloneWithFunctions(obj, map = new WeakMap()) {
  // https://chatgpt.com/share/6730f952-9fa8-800e-8833-265dd8d400ff
  // Handle non-objects like null and undefined
  if (obj === null || typeof obj !== 'object') return obj;

  // Handle circular references
  if (map.has(obj)) return map.get(obj);

  // Handle arrays
  if (Array.isArray(obj)) {
    const arrCopy = [];
    map.set(obj, arrCopy);
    obj.forEach((item, index) => {
      arrCopy[index] = deepCloneWithFunctions(item, map);
    });
    return arrCopy;
  }

  // Handle objects
  const clone = {};
  map.set(obj, clone);
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // Clone functions by reference; otherwise, recurse
      clone[key] = typeof value === 'function' ? value : deepCloneWithFunctions(value, map);
    }
  }
  return clone;
}

function Car() {
  return (
		<img alt="car" src={car} style={{position:"relative", right:"1px", top:"11px", width: "44px", height:"auto"}} />
	);
}

function Van() {
  return (
		<img alt="van" src={van} style={{position:"relative", right:"4px", top:"10px", width: "35px", height:"auto"}} />
	);
}

function Truck() {
  return (
		  <img alt="truck" src={truck} style={{position:"relative", top:"7px", width: "43px", height:"auto"}} />
	);
}

function MapWithMarkers({ markers, setMarkers, setMap }) {
	const birminghamCentre = { lat: 52.489471, lng: -1.898575 };
	const map = useMap();
	useEffect(() => {
		if (map) {
			setMap(map); // Pass map to parent component
		}
	}, [map, setMap]);

	const [visibleMarkers, setVisibleMarkers] = useState([]);
	const [pointerLocation, setPointerLocation] = useState(null);
// Function to check which markers are within the map bounds
	const updateVisibleMarkersCallback = useCallback(() => {
		if (!map)
			return;
		const bounds = map.getBounds();
/* can't get this working */
/*
		map.setOptions({
			animatedZoom: false,
		});
		map.setOptions({
			disableDoubleClickZoom: true,
		});
*/
		if (!bounds)
			return;

		const visible = markers.filter(({ lat, lng }) => {
		  const position = new window.google.maps.LatLng(lat, lng);
		  return bounds.contains(position);
		});
		console.log(`Updating visible markers ${visible.length}`);
		if (visible.length > 350) {
			map.setZoom(map.getZoom()+1);
			if (pointerLocation)
				map.panTo(pointerLocation);
		}
		setVisibleMarkers(visible);
	}, [markers, pointerLocation, map]);

	const updateVisibleMarkers = () => {

		const bounds = map.getBounds();
/* can't get this working */
/*
		map.setOptions({
			animatedZoom: false,
		});
		map.setOptions({
			disableDoubleClickZoom: true,
		});
*/
		if (!bounds)
			return;

		const visible = markers.filter(({ lat, lng }) => {
		  const position = new window.google.maps.LatLng(lat, lng);
		  return bounds.contains(position);
		});
		console.log(`Updating visible markers ${visible.length}`);
		if (visible.length > 350) {
			map.setZoom(map.getZoom()+1);
			if (pointerLocation)
				map.panTo(pointerLocation);
		}
		setVisibleMarkers(visible);
	};

	/* On refresh of browser and further changes */
	useEffect(() => {
		updateVisibleMarkersCallback();
	}, [markers, updateVisibleMarkersCallback]);


	const handleOnIdle = () => {
	};

	const handleOnBoundsChange = () => {
		setPointerLocation(map.getCenter());
		updateVisibleMarkers();
	};
/*
	const orange = "rgba(255,128,0,1.0)";
	const lightGrey = "rgba(192,192,192,1.0)";
	const purple = "rgba(255,0,255,1.0)";
	const red = "rgba(255,0,0,1.0)";
	const lightblue = "rgba(128,255,255,1.0)";
	const darkGreen = "rgba(0,128,0,1.0)";
	const lightGreen = "rgba(0,255,0,1.0)";
	const yellow = "rgba(255,255,0,1.0)";
	const darkGrey = "rgba(128,128,128,1.0)";
	const white = "rgba(255,255,255,1.0)";
	const blue = "rgba(0,0,255,1.0)";
*/
/*
	const markers = [
		{ lat: 52.4862, lng: -1.8904 }, // Birmingham
		{ lat: 51.5074, lng: -0.1278 }, // London
		{ lat: 53.4808, lng: -2.2426 }, // Manchester
		{ lat: 55.9533, lng: -3.1883 }, // Edinburgh
		{ lat: 53.8008, lng: -1.5491 }, // Leeds
	];
*/

	//console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

  const markerRefs = useRef({});
  const [markerZIndices, setMarkerZIndices] = useState({}); // Stores each marker's zIndex

  const checkAnchorOverlap = (anchorRect, draggedId) => {
	  for (const [id, ref] of Object.entries(markerRefs.current)) {
		  if (id !== draggedId && ref) { // Skip the dragged marker itself
			  const otherRect = ref.getBoundingClientRect();

			  const isOverlapping =
				  anchorRect.left < otherRect.right &&
				  anchorRect.right > otherRect.left &&
				  anchorRect.top < otherRect.bottom &&
				  anchorRect.bottom > otherRect.top;

			  if (isOverlapping) {
				  return id; // Return the ID of the overlapping marker
			  }
		  }
	  }
	  return null;
  };

  const calculateAnchorPosition = (anchorElement, anchorPoint) => {
	  const rect = anchorElement.getBoundingClientRect();
	  const anchorX = anchorPoint === AdvancedMarkerAnchorPoint.TOP_LEFT ? rect.left : rect.right;
	  const anchorY = anchorPoint === AdvancedMarkerAnchorPoint.TOP_LEFT ? rect.top : rect.bottom;

	  return {
		  left: anchorX,
		  right: anchorX,
		  top: anchorY,
		  bottom: anchorY,
	  };
  };

  const handleMarkerClick = (markerId) => {
	  const currentMaxZIndex = Math.max(...Object.values(markerZIndices), 0);

	  // Update z-index for the clicked marker to be one higher than the current max
	  setMarkerZIndices((prevZIndices) => ({
				  ...prevZIndices,
				  [markerId]: currentMaxZIndex + 1,
				  }));
  };

  return (
		<Map
			defaultCenter={birminghamCentre}
			defaultZoom={8}
			gestureHandling={"greedy"}
			mapId="DEMO_MAP_ID"
			onBoundsChanged={handleOnBoundsChange }
			onIdle={handleOnIdle}
		>
			{visibleMarkers.map((marker, index) => (
				<AdvancedMarker
					key={index}
					position={marker}
					anchorPoint={AdvancedMarkerAnchorPoint.TOP_LEFT}
					draggable={true}
					onClick={() => {
						marker.onclick();
						handleMarkerClick(marker.id)
					}} // Handle marker click
					zIndex={markerZIndices[marker.id]} // Set zIndex based on state
					onDragEnd={(coord) => {
						/* If you do not do a structured clone, marker will have already changed at the point this is invoked
						   and this will not work */
						marker.onclick();
						handleMarkerClick(marker.id);
						const anchorRect = calculateAnchorPosition(
								markerRefs.current[marker.id],
								AdvancedMarkerAnchorPoint.TOP_LEFT
								);
						const overlappingMarkerId = checkAnchorOverlap(anchorRect, marker.id);
						if (overlappingMarkerId) {
							 alert(`Marker with ID: ${marker.id} dropped on top of marker with ID: ${overlappingMarkerId}`);
						}
						const oldLoc = deepCloneWithFunctions(marker);
						//setMarkers([...markers.filter((m) => m.id !== oldLoc.id), oldLoc]);
						setVisibleMarkers((prevMarkers) => {return [...prevMarkers.filter((m) => m.id !== oldLoc.id), oldLoc]});
					}}
				>
				<div className="markerIcon"
					ref={(el) => (markerRefs.current[marker.id] = el)}
					style={{
					  backgroundColor: marker.backgroundColor
					}}
					  >
					  	<span className="markerPrefix">{marker.prefix}</span>
						{marker.project ? (<Car />) : marker.type === "TRUCK" ? (<Truck />) : (<Van / >)}
					{/*<span class="material-icons">face</span> */}
				</div>
					<span className="markerLabel">
						{marker.state}
					</span>
				</AdvancedMarker>
			))}
      </Map>
  );
}

function MapComponent({ markers, setMarkers, setMap}) {
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <MapWithMarkers markers={markers} setMarkers={setMarkers} setMap={setMap} />
    </APIProvider>
  );
}

export { MapComponent };
