import React, { useState, useEffect, useRef } from "react";
import ReactJson from "react-json-view";
import axios from "axios";
import { MapComponent } from "./google"; // Named import
import "./App.css";

export function crash(msg) {
	throw new Error(msg);
}

function formatTime(time_t) {
	// Convert the UNIX timestamp to milliseconds
	const date = new Date(time_t * 1000);

	// Extract hours and minutes, pad with leading zeros if needed
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");

	// Return in HH:MM format
	return `${hours}:${minutes}`;
}

const templates = {
	resource: {
		resource: {
			type: "VAN-K",
			contract: "PBC",
			state: "WORK",
			unprod: {
				type: "UNPROD_STATIC",
				customer: "INTERNAL",
				fin: 1723636935,
				state: "ARVD",
				to: null,
				and: {
					type: "BREAK",
					customer: "INTERNAL",
					earliest: 1723636872,
					latest: 1723644600,
					state: "PLAN",
					at: [],
					option: [
						{
							t: 1800,
						},
					],
					and: null,
					to: null,
					then: null,
					with: null,
				},
				with: null,
				then: null,
			},
			at: [51.71673, -0.27886],
			base: [51.76196, -0.46243],
			lastBreak: 1723624200,
			lastOn: 1723624200,
			lastOff: 0,
			roster: [
				{
					REST: 0,
				},
				{
					"S--": 1723624200,
				},
				{
					"X--": 1723649400,
				},
				{
					REST: 1723653000,
				},
			],
			lock: false,
			skills: {
				hazardLoc: 0,
				vrsLift: 0,
				towPole: 0,
				rearSpace: 1,
				partWeight: 1400,
				towWeight: 2000,
				seats: 2,
				excl: 0,
				RL1: 0,
				RL3: 0,
				RssBasicTools: 0,
				RssFullTools: 0,
				lockKit: 0,
				fwh: 1,
				mfw: 1,
				ML1: 0,
				glassWrap: 0,
			},
			id: 881460002,
		},
	},
	project: {
		project: {
			id: 78389355,
			stops: {
				rid: 243979398,
				dep: 1628778341,
				arv: 1628778346,
				type: "RCY",
				state: "DONE",
				customer: "RSS-AA PERSONAL",
				delayFactor: 1,
				at: [50.62637, -2.44396],
				option: [
					{
						t: 1500,
						tt: 900,
						tc: 300,
						rt: 750,
						skill: {
							bedWeight: 1041,
							bedLength: 4500,
							seats: 1,
						},
					},
					{
						t: 1200,
						tt: 900,
						tc: 300,
						rt: 750,
						skill: {
							partWeight: 624,
							towWeight: 1041,
							rearSpace: 1,
							vrsLift: 0,
							seats: 1,
						},
					},
				],
				lock: false,
				hold: false,
				to: {
					then: {
						to: {
							at: [51.50104, -0.14269],
							type: "UNLOAD",
							state: "PLAN",
							minLeaveIn: 480,
						},
						at: null,
						option: [
							{
								t: 1500,
								tt: 900,
								tc: 300,
								rt: 750,
								skill: {
									bedWeight: 1041,
									bedLength: 4500,
									seats: 1,
								},
							},
							{
								t: 1200,
								tt: 900,
								tc: 300,
								rt: 750,
								skill: {
									partWeight: 624,
									towWeight: 1041,
									rearSpace: 1,
									vrsLift: 0,
									seats: 1,
								},
							},
						],
						lock: false,
						type: "HBR_RELOAD",
					},
					earliest: null,
					latest: null,
					at: [],
					type: "HBR_50000",
				},
				earliest: 1628778330,
				latest: 1628783730,
			},
		},
	},
	queue: {
		queue: {
			rank: 1015,
			at: [52.47782, -1.91016],
			livery: ["VWG-RSS", "AA-RSS"],
			skills: {
				seats: 5.0,
				excl: 0.0,
				RL1: 0.0,
				RL3: 0.0,
				RssBasicTools: 0.0,
				RssFullTools: 0.0,
				BL1: 0.0,
				mfw: 1.0,
				ML1: 0.0,
				glassFit: 0.0,
				glassWrap: 0.0,
				glassRepair: 0.0,
			},
			id: -9800493,
		},
	},
	responsePoint: {
		responsePoint: {
			id: 90948635,
			stops: {
				rid: null,
				dep: 1333360214,
				type: "RAP",
				state: "PLAN",
				customer: "RSS-AA PERSONAL",
				delayFactor: 1,
				at: [52.37887, -1.51661],
				option: [
					{
						t: 1800,
						c: 270,
						tt: 0,
						tc: 0,
						rt: 0,
						rc: 0,
						skill: {
							techControl: 0,
						},
					},
				],
				lock: false,
				hold: false,
			},
			on: 28822,
			off: 72021,
			active: "SMTWTFSX",
			arriveWithin: 3600,
		},
	},
};

function getDescFunc(desc, state, id) {
	return `${desc} ${state ? state : ""} ${id}`;
}

class ResponsePoint {
	constructor(obj) {
		this.data = obj;
		this.desc = "ResponsePoint";
	}
	getBackgroundColor() {
		return null;
	}
	getDesc() {
		return getDescFunc(this.desc, null, this.getId());
	}
	getDescExtended() {
		return null;
	}
	getPrefix() {
		return "";
	}
	getId() {
		return this.data.id;
	}
	getRid() {
		return this.getId();
	}
	getData() {
		return this.data;
	}
	getMarker() {
		return null;
	}
	getBaseMarker() {
		return null;
	}
}

class Queue {
	constructor(obj) {
		this.data = obj;
		this.desc = "Queue";
	}
	getBackgroundColor() {
		return null;
	}
	getDesc() {
		return getDescFunc(this.desc, null, this.getId());
	}
	getDescExtended() {
		return null;
	}
	getPrefix() {
		return "";
	}
	getId() {
		return this.data.id;
	}
	getRid() {
		return this.getId();
	}
	getData() {
		return this.data;
	}
	getMarker() {
		return null;
	}
	getBaseMarker() {
		return null;
	}
}

class Stop {
	constructor(obj) {
		this.data = obj;
		this.desc = "Stop";
	}
	getBackgroundColor() {
		return null;
	}
	getDesc() {
		return getDescFunc(this.desc, null, this.getId());
	}
	getDescExtended() {
		return null;
	}
	getPrefix() {
		return "";
	}
	getId() {
		return this.data.spiralKey;
	}
	getRid() {
		if (this.data.rid) return this.data.rid;
		else return this.data.qid;
	}
	getData() {
		return this.data;
	}
	getMarker() {
		return null;
	}
	getBaseMarker() {
		return null;
	}
}

class Project {
	constructor(obj) {
		this.data = obj;
		this.desc = "Project";
		this.backgroundColor = "rgba(255,128,0,1.0)";
	}
	getBackgroundColor() {
		return this.backgroundColor;
	}
	getDesc() {
		return getDescFunc(this.desc, this.data.stops.state, this.data.id);
	}
	getDescExtended() {
		return null;
	}
	getPrefix() {
		return "";
	}
	getId() {
		return this.data.id;
	}
	getRid() {
		if (this.data.stops.rid) return this.data.stops.rid;
		else return this.data.stops.qid;
	}
	getData() {
		return this.data;
	}
	getMarker() {
		let marker = {
			project: {},
			id: `${this.desc}:${this.getId()}`,
			lat: this.getData().stops.at[0],
			lng: this.getData().stops.at[1],
			prefix: this.getPrefix(),
			state: this.getData().stops.state,
			type: null,
			backgroundColor: this.getBackgroundColor(),
		};
		return marker;
	}
	getBaseMarker() {
		return null;
	}
}

class Resource {
	constructor(obj) {
		this.data = obj;
		this.desc = "Resource";
		this.backgroundColor = "rgba(192,192,192,1.0)";
	}
	getBackgroundColor() {
		return this.backgroundColor;
	}
	getDesc() {
		return getDescFunc(this.desc, this.data.state, this.data.id);
	}
	getDescExtended() {
		return `${this.getDesc()} ${formatTime(this.getData().lastOn)}-${formatTime(this.getData().lastOff)}`;
	}
	getPrefix() {
		if (this.data.type === "TRUCK") return "Y";
		else return "K";
	}
	getId() {
		return this.data.id;
	}
	getRid() {
		return this.getId();
	}
	getData() {
		return this.data;
	}
	getMarker() {
		let marker = {
			resource: {},
			id: `${this.getDesc()}:${this.getId()}`,
			base: false,
			lat: this.getData().at[0],
			lng: this.getData().at[1],
			prefix: this.getPrefix(),
			state: this.getData().state,
			type: this.getData().type,
			backgroundColor: this.getBackgroundColor(),
		};
		return marker;
	}
	getBaseMarker() {
		let baseMarker = {
			id: `${this.desc}:${this.getId()}:base`,
			base: true,
			lat: this.getData().base[0],
			state: "BASE",
			lng: this.getData().base[1],
			onclick: function () {},
		};
		return baseMarker;
	}
}

function App() {
	const [dispatchVisible, setDispatchVisible] = useState(false);
	const [resourceEditorVisible, setResourceEditorVisible] = useState(false);
	const [jsonData, setJsonData] = useState(templates.resource);
	const [operation, setOperation] = useState("add");
	const [consoleLog, setConsoleLog] = useState([]); // To store WebSocket messages
	const [resourceList, setResourceList] = useState([]); // Array of resources
	const [resourceListDesc, setResourceListDesc] = useState([]); // Array of resources

	const [map, setMap] = useState(null);
	//const [selectedResource, setSelectedResource] = useState([]); // For the selected resource

	const [stopList, setStopList] = useState([]); // Array of resources
	const [stopListSelected, setStopListSelected] = useState(false);
	const [planWindowDesc, setPlanWindowDesc] = useState("Plan Window"); // Array of resources
	const [planWindowList, setPlanWindowList] = useState([]); // Array of resources
	//const [selectedStop, setSelectedStop] = useState([]); // For the selected resource

	const endOfConsoleRef = useRef(null);
	/*
    const defaultMarkers = [
        { lat: 52.4862, lng: -1.8904 }, // Birmingham
        { lat: 51.5074, lng: -0.1278 }, // London
        { lat: 53.4808, lng: -2.2426 }, // Manchester
        { lat: 55.9533, lng: -3.1883 }, // Edinburgh
        { lat: 53.8008, lng: -1.5491 }, // Leeds
    ];
*/
	const [markers, setMarkers] = useState([]);
	function centreMarkerOnMap(e, option) {
		e.preventDefault();
		const marker = option.obj.getMarker();
		if (map && marker) {
			const { lat, lng } = marker;
			map.setCenter({ lat, lng });
		}
	}
	function addToList(
		compareFunc,
		optionsFunc,
		obj,
		backgroundColor,
		marker,
		baseMarker,
		res,
		projects,
		rid,
	) {
		let onclick = function () {
			console.log(obj);
			if (res)
				setPlanWindowDesc(res.getDescExtended());
			else
				setPlanWindowDesc("<Missing qid>");
			if (baseMarker) {
				setMarkers((prevMarkers) => {
					return [...prevMarkers.filter((m) => !m.base), baseMarker];
				});
			} else
				setMarkers((prevMarkers) => {
					return [...prevMarkers.filter((m) => !m.baseMarker)];
				});
			setPlanWindowList((currentList) => {
				let ownedTasks = projects.filter((m) => m.getRid() === rid);
				//console.log(`Out of ${projects.length} projects, we found ${ownedTasks.length} with id ${rid}`);
				//console.log(ownedTasks);
				return ownedTasks;
			});
		};
		let ret = {};
		if (marker) {
			marker.onclick = onclick;
			ret.marker = marker;
		} else
			ret.marker = null;
		const option = {};
		option.obj = obj;
		option.onclick = onclick;
		option.value = obj.getDesc();
		option.backgroundColor = backgroundColor;
		ret.option = option;
		return ret;
	}
	//const [markers, setMarkers] = useState(defaultMarkers);

	// WebSocket connection setup
	// This code will run twice in strict mode - not suspected to be an issue
	// https://stackoverflow.com/questions/74952506/how-to-disable-strict-mode-in-react-18
	useEffect(() => {
		const ip = window.location.hostname;
		const port = process.env.REACT_APP_WEBSOCKET_PORT;
		let downloadFinished = 0;
		//const port = 8080;
		//const url = `http://${ip}:${port}/json`
		let ws;
		let resources = [];
		let projects = [];
		let responsePoints = [];
		let queues = [];
		let stops = [];
		let updates = [];
		updates["resources"] = 0;
		updates["projects"] = 0;
		updates["responsePoints"] = 0;
		updates["queues"] = 0;
		updates["stops"] = 0;
		let lastUpdate = new Date();
		function getResource(obj) {
			let ret = resources.find((item) => item.getId() === obj.getRid());
			if (!ret) ret = queues.find((item) => item.getId() === obj.getRid());
			return ret;
		}
		const connectWebSocket = () => {
			let wscon;
			if (window.location.protocol === "https:") {
				wscon = `https://${ip}/wss`;
			} else {
				wscon = `http://${ip}:${port}`;
			}
			console.log(wscon);
			ws = new WebSocket(wscon);
			setConsoleLog((prevLog) => [
				...prevLog,
				`(${new Date().toTimeString().split(" ")[0]}) **WebSocket connecting to ${wscon}`,
			]);
			ws.onmessage = (event) => {
				const messageString = `(${new Date().toTimeString().split(" ")[0]}) ${event.data}`;
				let message;
				//setConsoleLog(prevLog => [...prevLog, messageString]); // Append new message to the console log
				try {
					message = JSON.parse(event.data);
				} catch (error) {
					alert(error);
					throw error;
				}
				console.log("Found message");
				console.log(message);
				if (message.resource) {
					let obj = new Resource(message.resource);
					resources = [
						...resources.filter((i) => i.getId() !== obj.getId()),
						obj,
					];
				} else if (message.surgeZone) {
					throw new Error("surgeZone not supported");
				} else if (message.project) {
					let obj = new Project(message.project);
					projects = [
						...projects.filter((i) => i.getId() !== obj.getId()),
						obj,
					];
				} else if (message.queue) {
					let obj = new Queue(message.queue);
					queues = [...queues.filter((i) => i.getId() !== obj.getId()), obj];
				} else if (message.stop) {
					let obj = new Stop(message.stop);
					stops = [...stops.filter((i) => i.getId() !== obj.getId()), obj];
				} else if (message.responsePoint) {
					let obj = new ResponsePoint(message.stop);
					responsePoints = [
						...responsePoints.filter((i) => i.getId() !== obj.getId()),
						obj,
					];
				} else if (message.done) {
					downloadFinished = 1;
					setConsoleLog((prevLog) => [...prevLog, messageString]); // Append new message to the console log
				} else {
					console.log(message);
					throw new Error("Unexpected message");
				}
				if (!downloadFinished) {
					console.log("Skipping as download not done");
					return;
				}
				let markersToAdd = [];
				{
					let resAndQueueOptions = [];
					for (const obj of resources) {
						let clickEntity = addToList(
							(obj) => obj.getId(),
							setResourceList,
							obj,
							obj.getBackgroundColor(),
							obj.getMarker(),
							obj.getBaseMarker(),
							obj,
							projects,
							obj.getRid(),
						);
						if (clickEntity.marker) markersToAdd.push(clickEntity.marker);
						resAndQueueOptions.push(clickEntity.option);
					}
					for (const obj of queues) {
						let clickEntity = addToList(
							(obj) => obj.getId(),
							setResourceList,
							obj,
							obj.getBackgroundColor(),
							obj.getMarker(),
							obj.getBaseMarker(),
							obj,
							projects,
							obj.getRid(),
						);
						if (clickEntity.marker) markersToAdd.push(clickEntity.marker);
						resAndQueueOptions.push(clickEntity.option);
					}
					resAndQueueOptions = resAndQueueOptions.sort((a, b) =>
						a.value.localeCompare(b.value),
					);
					setResourceList(resAndQueueOptions);
				}
				setResourceListDesc("Queues/Resources - " + resources.length);
				{
					let projectAndStopOptions = [];
					for (const obj of projects) {
						let clickEntity = addToList(
							(obj) => obj.getId(),
							setStopList,
							obj,
							obj.getBackgroundColor(),
							obj.getMarker(),
							obj.getBaseMarker(),
							getResource(obj),
							projects,
							obj.getRid(),
						);
						if (clickEntity.marker) markersToAdd.push(clickEntity.marker);
						projectAndStopOptions.push(clickEntity.option);
					}
					for (const obj of stops) {
						let clickEntity = addToList(
							(obj) => obj.getId(),
							setStopList,
							obj,
							obj.getBackgroundColor(),
							obj.getMarker(),
							obj.getBaseMarker(),
							getResource(obj),
							projects,
							obj.getRid(),
						);
						if (clickEntity.marker) markersToAdd.push(clickEntity.marker);
						projectAndStopOptions.push(clickEntity.option);
					}
					setStopList(projectAndStopOptions);
				}
				/*
				for (const obj of responsePoints) {
					addToList((obj) => obj.getId(), setStopList, obj, obj.getBackgroundColor(), obj.getMarker(), obj.getBaseMarker(), getResource(obj), projects, obj.getRid());
				}
*/
				setMarkers(markersToAdd);
			};

			ws.onopen = (error) => {
				downloadFinished = 0;
				setMarkers([]);
				setResourceList([]);
				setStopList([]);
				setConsoleLog((prevLog) => [
					...prevLog,
					`(${new Date().toTimeString().split(" ")[0]}) **WebSocket connected`,
				]);
			};

			ws.onerror = (error) => {
				console.log(error);
				setConsoleLog((prevLog) => [
					...prevLog,
					`(${new Date().toTimeString().split(" ")[0]}) **WebSocket error`,
				]);
			};

			ws.onclose = () => {
				setConsoleLog((prevLog) => [
					...prevLog,
					`(${new Date().toTimeString().split(" ")[0]}) **WebSocket closed`,
				]);
				setTimeout(connectWebSocket, 5000);
			};
		};
		connectWebSocket();

		return () => {
			ws.close(); // Cleanup WebSocket when component unmounts
			ws = null;
		};
	}, []);

	useEffect(() => {
		if (endOfConsoleRef.current) {
			endOfConsoleRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [consoleLog]);

	const handlePost = async () => {
		try {
			const wrappedJson = { [operation]: jsonData };
			const wrappedJsonStr = JSON.stringify(wrappedJson);
			console.log("Posting");
			console.log(wrappedJsonStr);
			const ip = window.location.hostname;
			const port = process.env.REACT_APP_REST_PORT;
			const url = `http://${ip}:${port}/json`;
			console.log(url);
			const response = await axios.post(url, wrappedJson);
			console.log("Response:", response.data);
		} catch (error) {
			console.error("Error posting JSON:", error);
		}
	};

	return (
		<div className="App">
			<button
				onClick={() => setResourceEditorVisible(!resourceEditorVisible)}
				style={{
					position: "fixed",
					top: "13px",
					right: "175px",
					zIndex: 1000,
					padding: "10px",
					backgroundColor: "#007bff",
					color: "white",
					border: "none",
					borderRadius: "5px",
					cursor: "pointer",
				}}
			>
				{resourceEditorVisible ? "Hide Resource Editor" : "Show Resource Editor"}
			</button>

			<button
				onClick={() => setDispatchVisible(!dispatchVisible)}
				style={{
					position: "fixed",
					top: "13px",
					right: "55px",
					zIndex: 1000,
					padding: "10px",
					backgroundColor: "#007bff",
					color: "white",
					border: "none",
					borderRadius: "5px",
					cursor: "pointer",
				}}
			>
				{dispatchVisible ? "Hide Dispatch" : "Show Dispatch"}
			</button>
			<div>
				<div
					className="App2"
					style={{
						position: "absolute",
						top: 0,
						left: dispatchVisible ? "420px" : "0",
						width: dispatchVisible ? "calc(100% - 420px)" : "100%",
						height: "100vh",
						transition: "left 0.3s ease-in-out, width 0.3s ease-in-out",
						zIndex: 1,
					}}
				>
					<MapComponent
						markers={markers}
						setMarkers={setMarkers}
						setMap={setMap}
					/>
				</div>
			</div>
			<div>
				<div
					className="resourceEdit"
				style={{
					position: "fixed",
					top: 0,
					left: resourceEditorVisible ? 0 : "-675px", // Slide in/out
					width: "650px",
					height: "100%",
					backgroundColor: "white",
					boxShadow: "2px 0 5px rgba(0,0,0,0.3)",
					padding: "8px",
					overflowY: "auto",
					transition: "left 0.3s ease-in-out",
					zIndex: 1001,
				}}
				>
					<h2>Resource Editor</h2>
				</div>
			</div>

			<div
				className="entityEditor"
				style={{
					position: "fixed",
					top: 0,
					right: dispatchVisible ? 0 : "-675px", // Slide in/out
					width: "650px",
					height: "100%",
					backgroundColor: "white",
					boxShadow: "2px 0 5px rgba(0,0,0,0.3)",
					padding: "8px",
					overflowY: "auto",
					transition: "right 0.3s ease-in-out",
					zIndex: 999,
				}}
			>
				<h2>Entity Editor</h2>
				{/* Radio Buttons for Operations */}
				<div>
					<label>
						<input
							type="radio"
							value="add"
							checked={operation === "add"}
							onChange={(e) => setOperation(e.target.value)}
						/>{" "}
						Add
					</label>
					<label>
						<input
							type="radio"
							value="delete"
							checked={operation === "delete"}
							onChange={(e) => setOperation(e.target.value)}
						/>{" "}
						Delete
					</label>
					<label>
						<input
							type="radio"
							value="read"
							checked={operation === "read"}
							onChange={(e) => setOperation(e.target.value)}
						/>{" "}
						Read
					</label>
					<label>
						<input
							type="radio"
							value="update"
							checked={operation === "update"}
							onChange={(e) => setOperation(e.target.value)}
						/>{" "}
						Update
					</label>
				</div>
				{/* Entity type buttons */}
				<div>
					<button onClick={() => setJsonData(templates.resource)}>
						Resource
					</button>
					<button onClick={() => setJsonData(templates.queue)}>Queue</button>
					<button onClick={() => setJsonData(templates.project)}>
						Project
					</button>
					<button onClick={() => setJsonData(templates.responsePoint)}>
						Response Point
					</button>
				</div>
				<div
					style={{
						maxHeight: "calc(100% - 170px)",
						overflowY: "scroll", // Enable vertical scrolling if content exceeds height
						border: "1px solid #ccc", // Optional: Add a border for better visual appearance
						padding: "10px", // Optional: Padding around the JSON editor
					}}
				>
					<ReactJson
						src={jsonData}
						onEdit={(edit) => setJsonData(edit.updated_src)}
						onAdd={(add) => setJsonData(add.updated_src)}
						onDelete={(del) => setJsonData(del.updated_src)}
						collapsed={false}
						theme="monokai"
					/>
				</div>
				<button onClick={handlePost}>Send JSON to Crud</button>
			</div>
			<div
				className="dispatch-panel"
				style={{
					position: "fixed",
					top: 0,
					left: dispatchVisible ? 0 : "-725px", // Slide in/out
					width: "710px",
					height: "100vh",
					backgroundColor: "white",
					boxShadow: "2px 0 5px rgba(0,0,0,0.3)",
					padding: "8px",
					display: "flex",
					flexDirection: "column",
					transition: "left 0.3s ease-in-out",
					zIndex: 999,
				}}
			>
				<div style={{ flex: 1, overflowY: "auto" }}>
					<h2>Dispatch / Map</h2>
					<div
						style={{
							display: "flex",
							alignItems: "flex-start",
							marginRight: "20px",
						}}
					>
						<div style={{ marginRight: "0px" }}>
							<div style={{ height: "500px" }}>
								{resourceListDesc}
								<br />
								<div style={{
										width: "300px",
										height: '498px',
										overflowY: "scroll",
										border: '1px solid #ccc',
										fontFamily: 'sans-serif',
										fontSize: '13px' // matches native <select>
								}}>
								  {resourceList.map((option, index) => (
									<div
									  key={index}
									  style={{
										      backgroundColor: stopListSelected === option.value ? '#3399ff' : option.backgroundColor,
											  color: stopListSelected === option.value ? 'white' : 'black',
											  padding: '2px 8px',         // tighter padding like an <option>
											  cursor: 'default',
											  userSelect: 'pointer',         // disables text selection
											  whiteSpace: 'nowrap',
											  overflow: 'hidden',
											  textOverflow: 'ellipsis',   // for long values
									  }}
									 	onClick={() => {
											console.log(option)
											setStopListSelected(option.value);
										}}
									  onDoubleClick={() => {
										// Toggle a boolean value or call a handler
										console.log(`Double clicked: ${option.value}`);
										console.log(option);
										setJsonData(option.obj.data);
										setOperation("update");
										//option.someBoolean = true; // if you're using a state object, update state instead
										// setStopList([...stopList]); // trigger re-render if needed
									  }}
									  onContextMenu={(e) => {
										e.preventDefault();
										centreMarkerOnMap(e, option);
									  }}
									>
									  {option.value}
									</div>
								  ))}
								</div>



		{
		/*
								<select
									multiple={true}
									//value={selectedResource}
									onChange={(e) => {
										//const options = [...e.target.selectedOptions];
										//const values = options.map(option => option.value);
										// TODO - Deletes the list, need to fix this
										//setResourceList(values);
									}}
									style={{
										height: "100%",
										width: "300px",
										marginRight: "10px",
									}}
									size={5}
								>
									{resourceList.map((option, index) => (
										<option
											key={index}
											value={option.value}
											style={{
												backgroundColor: option.backgroundColor,
											}}
											onClick={() => option.onclick()}
											onContextMenu={(e) => {
												centreMarkerOnMap(e, option);
											}}
										>
											{option.value}
										</option>
									))}
								</select>
								*/
		}
							</div>
							<div>
								<br />
								Messages
								<br />
								<select
									multiple={true}
									//value={selectedResource}
									onChange={(e) => {
										//const options = [...e.target.selectedOptions];
										//const values = options.map(option => option.value);
										// TODO - Deletes the list, need to fix this
										//setResourceList(values);
									}}
									style={{
										height: "200px",
										width: "300px",
										marginRight: "10px",
									}}
									size={5}
								></select>
							</div>
						</div>
						<div>
							<div style={{ height: "500px" }}>
								Project & Stops
								<br />
								<div style={{
									width: '398px',
										height: '498px',
										overflowY: "scroll",
										border: '1px solid #ccc',
										fontFamily: 'sans-serif',
										fontSize: '13px' // matches native <select>
								}}>
								  {stopList.map((option, index) => (
									<div
									  key={index}
									  style={{
										      backgroundColor: stopListSelected === option.value ? '#3399ff' : option.backgroundColor,
											  color: stopListSelected === option.value ? 'white' : 'black',
											  padding: '2px 8px',         // tighter padding like an <option>
											  cursor: 'default',
											  userSelect: 'pointer',         // disables text selection
											  whiteSpace: 'nowrap',
											  overflow: 'hidden',
											  textOverflow: 'ellipsis',   // for long values
									  }}
									 	onClick={() => {
											console.log(option)
											setStopListSelected(option.value);
										}}
									  onDoubleClick={() => {
										// Toggle a boolean value or call a handler
										console.log(`Double clicked: ${option.value}`);
										console.log(option);
										setJsonData(option.obj.data);
										setOperation("update");
										//option.someBoolean = true; // if you're using a state object, update state instead
										// setStopList([...stopList]); // trigger re-render if needed
									  }}
									  onContextMenu={(e) => {
										e.preventDefault();
										centreMarkerOnMap(e, option);
									  }}
									>
									  {option.value}
									</div>
								  ))}
								</div>
							</div>
							<div>
								<br />
								{planWindowDesc}
								<br />
								<select
									multiple={true}
									//value={selectedStop}
									onChange={(e) => {
										//const options = [...e.target.selectedOptions];
										//const values = options.map(option => option.value);
										// TODO - Deletes the list, need to fix this
										//setStopList(values);
									}}
									//onChange={(e) => setStopList(e.target.value)}
									style={{ height: "200px", width: "400px" }}
									size={5}
								>
									{planWindowList.map((option, index) => (
										<option
											key={index}
											value={option.getId()}
											style={{
												backgroundColor: option.getBackgroundColor(),
											}}
											onClick={() => console.log(option)}
											onContextMenu={(e) => {
												centreMarkerOnMap(e, option);
											}}
										>
											{option.getDesc()}
										</option>
									))}
								</select>
							</div>
						</div>

						{/* Right side: Map component */}
					</div>
				</div>

				{/* WebSocket Console */}
				<div className="console" style={{paddingBottom: "40px"}}>
					<h2>Dispatch Stream</h2>
					<div
						style={{
							backgroundColor: "#000",
							color: "#fff",
							padding: "10px",
							height: "250px", // Adjust the console size dynamically
							overflowY: "scroll",
							whiteSpace: "pre-wrap", // Ensure the text wraps
							wordWrap: "break-word", // Force breaking long words
						}}
					>
						{consoleLog.map((message, index) => (
							<div key={index}>{message}</div>
						))}
						<div ref={endOfConsoleRef} />{" "}
						{/* This div will always be at the bottom */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
